<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="addNewCard" lazy aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.add_new_card}}</span>
                        </div>
                        <div @click="hide" id="hideContractCard" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl" class="m-2">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="3">
                                <label>{{ lang.company_name }}</label>
                                <b-form-select class="selborder" v-model="customer_id" :options="customersList"/>
                            </v-col>
                            <v-col cols="12" md="3">
                                <label>{{ lang.technical }}</label>
                                <b-form-select class="selborder" v-model="card_supervisor" :options="usersList"/>
                            </v-col>
                            <v-col cols="12" md="3">
                                <label>{{ lang.date }}</label>
                                <b-form-input type="date" class="inborder" v-model="card_date" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.plate_number }}</th>
                                    <th class="text-center backBlack">{{ lang.brand }}</th>
                                    <th class="text-center backBlack">{{ lang.model }}</th>
                                    <th class="text-center backBlack">{{ lang.car_year }}</th>
                                    <th class="text-center backBlack">{{ lang.color }}</th>
                                    <th class="text-center backBlack">{{ lang.vin }}</th>
                                    <th class="text-center backBlack">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in cars" :key="index">
                                    <td>{{ item.plate_number }}</td>
                                    <td>{{ item.brand }}</td>
                                    <td>{{ item.model }}</td>
                                    <td>{{ item.car_year }}</td>
                                    <td>{{ item.color }}</td>
                                    <td>{{ item.vin }}</td>
                                    <td style="cursor:pointer;margin-left: 5px;width:80px;background:red;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" style="background:red !important" @click="deleteROw(index)">{{lang.delete}}</div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>
                                        <b-form-input class="inborder" v-model="newrow.plate_number" />
                                    </th>
                                    <th>
                                        <b-form-input class="inborder" v-model="newrow.brand" />
                                    </th>
                                    <th>
                                        <b-form-input class="inborder" v-model="newrow.model" />
                                    </th>
                                    <th>
                                        <b-form-input class="inborder" v-model="newrow.car_year" />
                                    </th>
                                    <th>
                                        <b-form-input class="inborder" v-model="newrow.color" />
                                    </th>
                                    <th>
                                        <b-form-input class="inborder" v-model="newrow.vin" />
                                    </th>
                                    <td style="cursor:pointer;margin-left: 5px;width:80px;background:blue;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" @click="addRow()">{{lang.add}}</div>
                                    </td>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                    </v-col>  
                    <v-col cols="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="width:100px;">{{ lang.plate_number }}</th>
                                    <th class="text-center backBlack">{{ lang.item_code }}</th>
                                    <th class="text-center backBlack" style="width:70px;">{{ lang.qtty }}</th>
                                    <th class="text-center backBlack" style="width:70px;">{{ lang.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in card_items" :key="index">
                                    <td>{{ item.plate_number }}</td>
                                    <td>{{ item.item_name }}</td>
                                    <td>{{ item.qtty }}</td>
                                    <td style="cursor:pointer;margin-left: 5px;width:80px;background:red;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" style="background:red !important" @click="deleteROw(index)">{{lang.delete}}</div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>
                                        <b-form-select class="selborder" v-model="itemnewrow.plate_number" :options="carsList" />
                                    </th>
                                    <th>
                                        <b-form-select class="selborder" v-model="itemnewrow.plate_number" :options="itemsList" />
                                    </th>
                                    <th>
                                        <b-form-input class="inborder" v-model="itemnewrow.qtty" />
                                    </th>
                                    <td style="cursor:pointer;margin-left: 5px;width:80px;background:blue;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                                        <div class="viewBTN" @click="addRow()">{{lang.add}}</div>
                                    </td>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                    </v-col>        
                </v-row>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addJobOrder()" variant="success" class="ma-2" style="width:100px;">{{lang.add}}</b-button>
                    </div>
                </template>
            </b-sidebar>
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default{
    data() {
        return {
            customer_id: 0,
            card_date:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            items: [],
            cars: [],
            newrow: {
                plate_number: '',
                brand: '',
                model: '',
                car_year: '',
                color: '',
                vin: '',
            },
            itemnewrow: {
                plate_number: '',
                item_code: '',
                item_name: '',
                qtty: '',
            },
            card_items: [],
            conditions: [],
            customers: [],
            users: [],
            card_supervisor: '',
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        customersList: function(){
            let t = []
            for(let i=0;i<this.customers.length;i++){
                if(this.customers[i].customer_type == 1){
                    t.push({text: this.customers[i].full_name,value:this.customers[i].id})
                }
                if(this.customers[i].customer_type == 2){
                    t.push({text: this.customers[i].company_name,value:this.customers[i].id})
                }
                if(this.customers[i].customer_type == 3){
                    t.push({text: this.customers[i].entity_name,value:this.customers[i].id})
                }
            }
            return t;
        },
        usersList: function(){
            let t = []
            for(let i=0;i<this.users.length;i++){
                t.push({text: this.users[i].full_name,value:this.users[i].id})
            }
            return t;
        },
        platesNumber: function()
        {
            let t = [];
            for(let i=0; i<this.cars.length;i++){
                t.push({
                    text: this.cars[i][0].plate_number,
                    value: this.cars[i][0].plate_number
                })
            } 
            return t;
        },
        itemsList: function()
        {
            let t = [];
            for(let i=0; i<this.cars.length;i++){
                t.push({
                    text: this.items[i].item_name,
                    value: this.items[i].id
                })
            } 
            return t;
        }
        
    },
    methods: {
        deleteROw(index){
            this.cards.splice(index,1)
        },
        resetAll(){
            this.contract_id =  0;
            this.job_date =   (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.project
        },
        updateIt(item){
            // console.log(item);
            this.contract_id = item.id;
            this.items = item.items;
            this.cars= item.cars;
        },
        getCardSettigns(){
            const post = new FormData();
            post.append("type", "getCardSettings");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",1)
            
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response){
                    this.customers = response.data.results.customers
                    this.users = response.data.results.users
                }
            })
        },
        addJobOrder(){
            const post = new FormData();
            post.append("type", "addCarContractJob");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.contract_id)
            for(let i=0;i<this.cards.length;i++){
                Object.keys(this.cards[i]).forEach(key => {
                    post.append('data[cards]['+i+']['+key+']',this.cards[i][key])
                })
            }
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response){
                    document.getElementById('hideContractCard').click();
                    this.$parents.getContracts(this.contract_id)
                }
            })
        },
        addRow(){  
            if(this.newrow.plate_number == ''){
                let message = "يرجى اختيار رقم اللوحة المراد اضافة الصيانة لها";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: 'موافق', 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                                // document.getElementById('addModalBTN').click()
                            } 
                        },
                    ]
                });
                return false;
            }
            
            if(this.newrow.item_code == ''){
                let message = "يرجى اختيار الصنف";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: 'موافق', 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                                // document.getElementById('addModalBTN').click()
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.newrow.qtty == '' || this.newrow.qtty == 0){
                let message = "يرجى اضافة الكمية قبل المتابعة";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: 'موافق', 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                                // document.getElementById('addModalBTN').click()
                            } 
                        },
                    ]
                });
                return false;
            }
            let item_name = '';
            let item_id = '';
            let carid = 0
            // // console.log("dfs",this.items);
            // for(let i=0;i<this.items;i++){
            Object.keys(this.items).forEach(key => {
               
                if(this.items[key].id == this.newrow.item_code){
                    item_name = this.items[key].item_name
                    item_id = this.items[key].id
                }
            })
                
            for(let i=0;i<this.cars;i++){
                if(this.cars[i].plate_number == this.newrow.plate_number){
                    carid = this.cars[i].id
                }
            }
            
            this.cards.push({
                plate_number: this.newrow.plate_number,
                item_code: this.newrow.item_code,
                item_name: item_name,
                carid: carid,
                qtty: this.newrow.qtty,
                item_id: item_id
            })
            
            this.newrow = {
                plate_number: '',
                item_id: '',
                qtty: '',
            }
        }
        
    },
    created() {
        this.getCardSettigns();
    },
}
</script>